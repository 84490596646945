<template>
  <div class="row-container">

    <iframe frameborder="0" :src="webrdp_url"
      class="second-row" v-if="rdploaded && showjumbo == false" v-on:load="deleteCookie" id="rdpframe"></iframe>
    <div class="second-row" v-if="rdploaded == false">
        <div class="text-center" style="position: sticky; top:25%;">
          <i class="fa fa-cog fa-spin fa-15x fa-fw" style="color: #51bcda;"></i>x
          <p class="lead">Loading WebRDP</p>
        </div>
    </div>

    <div class="second-row" v-if="showjumbo">
      <div class="text-center" style="width: 60%;top: 100px;left: 20%;position: relative;">
        <div class="jumbotron">
          <h2 class="display-4">{{ $t('rdpdowntitle') }}</h2>
          <p class="lead">
            {{ $t('rdpdownmsg') }}
          </p>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import Vue from 'vue'
  import {Button} from 'src/components/UIComponents';
  import AppFooter from 'src/components/Dashboard/Views/Pages/Layout/AppFooter'

  Vue.use(require('vue-moment'))

  const def_banners = [
    {
      'banner': "/static/Banner1.jpeg",
      'banner_url': 'https://www.hokocloud.com'
    },
    {
      'banner': "/static/Banner2.jpeg",
      'banner_url': 'https://www.hokocloud.com'
    },
    {
      'banner': "/static/Banner3.jpeg",
      'banner_url': 'https://www.hokocloud.com'
    }
  ]
  // Cycle every 30 seconds
  const banner_countdown = 30000;
  const docbody_countdown = 5000;
  let win_focus = true;

  export default {
    props: {
      logo: {
        type: String,
        default: '/static/img/logo.png',
        description: 'Hoko Logo'
      }
    },
    components: {
      AppFooter, [Button.name]: Button
    },
    methods: {
      deleteCookie() {
        let cname = "GUAC_AUTH";
        //Create an date object
        let d = new Date();
        //Set the time to the past. 1000 milliseonds = 1 second
        d.setTime(d.getTime() - (60000*60*60*24));
        //Compose the expirartion date
        let expires = "expires=" + d.toGMTString();
        //Set the cookie with name and the expiration date
        //const expiry = cname+"=; "+ expires +"; path=/rdp;";
        const expiry = cname+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/rdp;";
        window.document.cookie = expiry;
        // Access Iframe Body
        let iframebody = window.frames['rdpframe'].contentWindow;
        let b = null;
        try {
          b = iframebody.document;
        }
        catch(e) { console.log(e); }
        if (b !== null) {
          this.checkDocumentBody();
        }
        // Start timer
        this.startofday = this.$moment('timezone', 'Europe/London', 'LLLL ss').startOf("day");
        this.startTimer();
      },
      checkDocumentBody() {
        let iframebody = window.frames['rdpframe'].contentWindow.document.location.href;
        if (iframebody.match(/client/ig) !== null) {
          setTimeout(this.checkDocumentBody, docbody_countdown);
        }
        else {
          window.close();
        }
      },
      cycleBanner() {
        //console.log(this.bannerload);
        if (this.bannerload >= this.bannercount) {
          this.bannerload = 0;
        }
        //console.log(this.bannerload);
        if (this.banners[this.bannerload] !== undefined) {
          this.adurl = this.banners[this.bannerload].banner_url;
          this.adbanner = this.banners[this.bannerload].banner;
          this.bannerload += 1;
        }
        setTimeout(this.cycleBanner, banner_countdown);
      },
      loadBanners(resp) {
        //console.log(resp.msg.length);
        if (resp.msg.length <= 0) {
          this.banners = def_banners;
          this.bannercount = def_banners.length;
        } else {
          this.bannercount = resp.msg.length;
          for (var i=0;i<this.bannercount;i++) {
            let bannerdata = {
              'banner': resp.msg[i]['banner_link'],
              'banner_url': resp.msg[i]['banner_url'],
              'inprogress': false,
              'id': parseInt(resp.msg[i]['id'])
            }
            this.banners.push(bannerdata);
          }
        }
        //console.log(this.banners);
        // Randomize Banner Order
        this.banners = this.shuffle(this.banners);
        //console.log(this.banners);
        // Cycle Banners
        this.cycleBanner();
      },
      shuffle(o) {
	       for (var j, x, i = o.length; i; j = parseInt(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
	       return o;
      },
      startTimer() {
        var self = this;
        if (win_focus) {
          const timeinsec = this.startofday.add(1, 'second');

          this.counter = timeinsec.format('HH:mm:ss');
          this.intcounter = parseInt(timeinsec.format('HHmmss'));
        }
        setTimeout(self.startTimer, 1000);
      }
    },
    data() {
      return {
        powered: "Powered By",
        showjumbo: false,
        webrdp_url: null,
        rdploaded: false,
        banners: [],
        bannercount: 0,
        bannerload: 0,
        adurl: null,
        adbanner: null,
        counter: '00:00:00',
        startofday: null,
        intcounter: 0
      }
    },
    mounted () {
      document.getElementById("main").classList.add("row-container");

      const user_data = JSON.parse(localStorage.getItem('user-info'));
      const goturl = resp => {
        this.rdploaded = true;
        if (!resp) {
          this.showjumbo = true;
        } else {
          // Some url encoding fix
          let rdpurl = resp.url.replace("&amp;", "&");
          this.webrdp_url = rdpurl;
        }
      };
      const failop = error => {
        this.rdploaded = true;
        this.showjumbo = true;
      };
      const load = resp => {
        if (!resp.success) {
          failop(resp)
          return;
        }
        this.$getRDPURL(resp)
          .then(goturl, failop);
      };
      // Get WEBRDPURL for user
      this.$getrdpToken(user_data, this.$route.params.rdp)
        .then(load, failop)
    }
  }
</script>
<style>
  body, html {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0
  }
  .row-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    background-color: white;
    overflow: hidden;
  }
  .first-row {
    border-top: 1px solid black;
    background-color: white;
    padding-bottom: 25px;
  }
  .second-row {
    padding: 0px !important;
    flex-grow: 1;
    border: none;
    margin: 0;
    padding: 0;
  }
  .fa-15x {
    font-size: 15em;
  }
  .meine-op-btn:hover {
    background-color: #fff !important;
  }
  .meine-op-btn:hover i.fa-trash-o {
    color: red !important;
  }
  .meine-op-btn:hover i {
    color: #51bcda !important;
  }
</style>
